.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.language-select-container {
  --flag-width: 32px;
  position: relative;
  font-size: 0.75rem;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  flex-direction: row;
  background-color: #2e4159;
  max-width: 100%;
  padding: 0 20px 0 0;
  margin-left: -0.5rem;
}
.language-select-container > .nav-item {
  text-transform: uppercase;
}
.language-select-container > .nav-item .img-wrap {
  position: relative;
  display: block;
}
.language-select-container > .nav-item img {
  display: block;
  position: relative;
  width: var(--flag-width);
  height: auto;
}
.language-select-container > .nav-item img.active {
  z-index: 1;
  transition: all 0.2s linear;
  opacity: 0;
}
.language-select-container > .nav-item img.inactive {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.language-select-container > .nav-item.active {
  font-weight: bold;
}
.language-select-container > .nav-item.active img.active,
.language-select-container > .nav-item .nav-link:hover img.active {
  opacity: 1;
}
.language-select-container > .nav-item:first-child {
  position: relative;
  padding-right: 7px;
  margin-right: 5px;
}
.language-select-container .nav-link {
  padding: 0.5rem;
}
@media (min-width: 992px) {
.language-select-container {
    background: transparent;
    display: flex;
    padding: 0;
    margin-left: 30px;
}
}