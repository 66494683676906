.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.navbar-top {
  display: block;
}
@media (min-width: 992px) {
.navbar-top {
    padding-top: 10px;
    position: relative;
}
.navbar-top::after {
    content: "";
    display: block;
    position: absolute;
    background: #d8d8d8;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    z-index: 0;
}
}
.navbar-container-lg {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
.navbar-container-lg {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 960px;
}
}
@media (min-width: 1260px) {
.navbar-container-lg {
    max-width: 1200px;
}
}
.navbar-brand {
  padding-left: 15px;
  padding-bottom: 21px;
}
.navbar-brand img {
  width: 150px;
  height: auto;
}
.navbar-brand.hide-brand {
  opacity: 0;
  visibility: hidden;
  padding: 0;
}
@media (min-width: 992px) {
.navbar-brand {
    position: relative;
    z-index: 13;
    padding: 0 0 15px 0;
}
.navbar-brand.hide-brand {
    display: none;
}
.navbar-brand img {
    max-width: 255px;
    max-height: 68px;
}
}
.nav-profile-image {
  flex: 0 0 48px;
  border-radius: 50%;
  display: block;
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  overflow: hidden;
}
.nav-user {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: 23px;
  transition: opacity 0.2s linear;
}
.nav-user span {
  cursor: pointer;
  display: none;
  margin-left: 26px;
}
.nav-user span:first-child {
  margin-left: 0;
}
.nav-user .nav-icon {
  color: #b0b0b0;
}
@media (min-width: 320px) {
.nav-user .user-logout {
    display: block;
}
}
@media (min-width: 400px) {
.nav-user .user-account-info {
    display: block;
}
.nav-user .user-account-info .ba-image-fill {
    display: block;
}
}
@media (min-width: 992px) {
.nav-user {
    display: none;
}
}
@media (min-width: 992px) {
.navbar-collapse {
    padding-top: 0;
    flex: 0 1 auto;
}
}
.navbar-user-logged-in .navbar-collapse {
  margin-top: -100px;
  position: relative;
  z-index: 10;
}
@media (min-width: 992px) {
.navbar-user-logged-in .navbar-collapse {
    margin-top: 0;
    position: static;
}
}
.navbar-user-logged-in.no-brand .navbar-collapse {
  margin-top: -60px;
}
@media (min-width: 992px) {
.navbar-user-logged-in.no-brand .navbar-collapse {
    margin-top: 0;
}
}
.navbar-user-logged-in .navbar-brand {
  transition: opacity 0.2s linear;
}
.navbar-user-logged-in.navbar-collapse-show .navbar-brand {
  opacity: 0;
}
@media (min-width: 992px) {
.navbar-user-logged-in.navbar-collapse-show .navbar-brand {
    transition: none;
    opacity: 1;
}
}
.navbar-top-bar {
  background-color: #2e4159;
  font-weight: 500;
  position: relative;
  width: 100%;
}
@media (max-width: 767.98px) {
.navbar-top-bar .b-nav-dropdown.show {
    margin-right: 15px;
    margin-bottom: 15px;
    flex: 1 1 100%;
    max-width: 400px;
}
}
@media (max-width: 991.98px) {
.navbar-top-bar > .container {
    padding-right: 0;
}
}
@media (min-width: 992px) {
.navbar-top-bar {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
}
.navbar-top-bar.no-auth {
    top: 10px;
}
.navbar-top-bar > .container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px 0 0;
}
.navbar-top-bar > .container .topbar-menu-container {
    order: 0;
}
.navbar-top-bar > .container .language-select-container {
    order: 2;
}
}
.navbar-light .navbar-top-bar .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
  transition: color 0.2s linear;
}
.navbar-light .navbar-top-bar .navbar-nav .nav-link:hover, .navbar-light .navbar-top-bar .navbar-nav .nav-link:focus, .navbar-light .navbar-top-bar .navbar-nav .nav-link.active {
  color: #fbfbfb;
}
.navbar-light .navbar-top-bar .navbar-nav .active > .nav-link {
  color: #fbfbfb;
}
@media (min-width: 360px) {
.nav-container {
    padding-left: 15px;
    padding-right: 15px;
}
}
@media (min-width: 576px) {
.nav-container {
    padding-left: 45px;
    padding-right: 45px;
}
}
@media (min-width: 992px) {
.nav-container {
    padding: 0;
}
}
.nav-user .fa-2x {
  font-size: 2rem;
}
@media print {
.navbar-top {
    padding: 0;
}
.nav-user,
  .navbar-top-bar,
  .navbar-toggler,
  #nav-collapse-main {
    display: none;
}
}