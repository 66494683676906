.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.main-nav {
  position: relative;
  z-index: 11;
  padding-bottom: 18px;
}
.main-nav .nav-item {
  width: 100%;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.41px;
  text-transform: uppercase;
}
.main-nav .nav-item.hide-mobile {
  display: none;
}
.main-nav .nav-item svg {
  display: inline-block;
  margin-right: 14px;
}
.main-nav .nav-item .icon-wrap {
  display: inline-block;
  width: 26px;
}
.main-nav .nav-item .icon-wrap svg {
  margin-right: 0;
}
.main-nav .nav-item .nav-link {
  display: block;
  padding: 18px 0 18px 0;
  transition: color 0.2s linear;
}
.main-nav .nav-item .nav-link:hover, .main-nav .nav-item .nav-link:focus {
  color: #2e4159;
}
.main-nav .nav-item .router-link-active {
  color: #2e4159;
}
@media (min-width: 992px) {
.main-nav .nav-item {
    padding-right: 9px;
    padding-left: 9px;
}
.main-nav .nav-item.hide-mobile {
    display: block;
}
.main-nav .nav-item svg,
  .main-nav .nav-item .icon-wrap {
    display: none;
}
.main-nav .nav-item .nav-link {
    height: 52px;
    padding: 9px 3px 0 3px;
    display: block;
    border-bottom: 3px solid #d8d8d8;
    transition: color 0.2s linear, border-bottom-color 0.2s linear;
}
.main-nav .nav-item .nav-link:hover, .main-nav .nav-item .nav-link:focus {
    border-color: #2e4159;
}
.main-nav .nav-item .router-link-active {
    border-color: #2e4159;
}
}
@media (min-width: 1260px) {
.main-nav .nav-item {
    padding-right: 26px;
    padding-left: 26px;
}
.main-nav .nav-item::after {
    content: "";
    display: block;
    background: #d8d8d8;
    height: 38px;
    width: 2px;
    position: absolute;
    top: 0;
    right: -1px;
}
.main-nav .nav-item:first-child {
    padding-left: 0;
}
.main-nav .nav-item:last-child {
    padding-right: 0;
}
.main-nav .nav-item:last-child::after {
    display: none;
}
}
.main-nav .individual-width > a {
  width: fit-content;
  white-space: nowrap;
}
@media (min-width: 992px) {
.main-nav {
    padding-bottom: 0;
    margin: 62px -3px 0 0;
}
}
@media print {
.main-nav {
    display: none;
}
}