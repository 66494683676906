.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.ba-group-password {
  position: relative;
}
.ba-group-password .input-group-append {
  cursor: pointer;
  border: 1px solid #b0b0b0;
  border-left-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
}
.has-error .ba-group-password .input-group-append {
  border-color: #e92422;
  color: #e92422;
}