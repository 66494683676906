.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.topbar-menu-container {
  --topbar-nav-link-bg: #3c536c;
  display: none;
  max-width: 70%;
  flex-direction: row;
  justify-content: right;
}
.topbar-menu-container.no-auth {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  max-width: 100%;
}
.topbar-menu-container .dropdown-menu-right {
  margin-top: 0;
}
@media (min-width: 576px) {
.topbar-menu-container .dropdown-menu-right {
    margin-bottom: 10px;
}
}
@media (min-width: 768px) {
.topbar-menu-container .dropdown-menu-right {
    position: absolute;
    margin-bottom: 0;
}
}
@media (min-width: 992px) {
.topbar-menu-container .dropdown-menu-right {
    margin-top: -3px;
}
}
.topbar-menu-container > .nav-item:not(:first-child) {
  margin-left: 21px;
}
.topbar-menu-container > .nav-item > .nav-link {
  font-size: 0.75rem;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 9px;
  line-height: 21px;
  text-align: center;
}
.topbar-menu-container > .nav-item > .nav-link:hover, .topbar-menu-container > .nav-item > .nav-link:focus {
  background: var(--topbar-nav-link-bg);
}
.topbar-menu-container > .nav-item.show > .nav-link {
  background: var(--topbar-nav-link-bg);
  letter-spacing: 0.9px;
  text-transform: uppercase;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 9px;
  line-height: 21px;
}
@media (max-width: 991.98px) {
.topbar-menu-container > .nav-item > .btn {
    font-size: 0.75rem;
    border-radius: 0;
}
}
@media (min-width: 992px) {
.topbar-menu-container {
    display: flex;
    flex-wrap: nowrap;
    --topbar-nav-link-bg: #2e4159;
}
.topbar-menu-container.no-auth {
    flex: 0 1 auto;
    max-width: 100%;
    margin-right: 0;
}
.topbar-menu-container > .nav-item > .dropdown-toggle {
    display: inline-block;
    color: #1a1a1a;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 0 solid transparent;
    padding: 0.8125rem 0.875rem;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-transform: uppercase;
    border-radius: 23px;
    font-weight: 500;
    padding: 0.8125rem 1.5rem 0.625rem 1.5rem;
    background-color: #52759f;
    text-decoration: none;
}
}
@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
.topbar-menu-container > .nav-item > .dropdown-toggle {
    transition: none;
}
}
@media (min-width: 992px) {
.topbar-menu-container > .nav-item > .dropdown-toggle:hover {
    color: #fff;
    text-decoration: none;
}
.topbar-menu-container > .nav-item > .dropdown-toggle[aria-expanded=true] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.topbar-menu-container > .nav-item.show > .dropdown-toggle {
    background: var(--topbar-nav-link-bg);
    letter-spacing: 0.9px;
    padding: 0.8125rem 1.5rem 0.625rem 1.5rem;
    line-height: 1.25;
}
}
.b-nav-dropdown {
  text-align: center;
}